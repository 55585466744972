<template>
  <router-layout>
    <div class="page">
      <div class="job_head">
        <div class="head">
          <van-icon name="arrow-left" @click="Back" />
          <van-search class="search" placeholder="请搜索求职信息" readonly @click="toSearch" />
        </div>
        <div class="job_drop">
          <div class="drop_r" :class="{active:filterShow}" @click="filterMore">
            <span class="iconfont icon-shaixuan"></span>
          </div>
          <div class="drop_l">
            <van-dropdown-menu active-color="#fa683b">
              <van-dropdown-item
                :title="typeTitle"
                v-model="typeVal"
                :options="type"
                @open="closeFilter"
                @change="typeChange"
              />
              <van-dropdown-item
                :title="areaTitle"
                v-model="areaVal"
                :options="area"
                @open="closeFilter"
                @change="areaChange"
              />
              <van-dropdown-item
                :title="sexTitle"
                v-model="sexVal"
                :options="sex"
                @open="closeFilter"
                @change="sexChange"
              />
              <van-dropdown-item
                :title="studyTitle"
                v-model="studyVal"
                :options="study"
                @open="closeFilter"
                @change="studyChange"
              />
            </van-dropdown-menu>
          </div>
        </div>
      </div>
      <div class="job_body">
        <van-loading
          color="#1989fa"
          type="spinner"
          class="dataLoading"
          v-if="$store.state.dataLoading"
        />
        <div v-else>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            offset="10"
            v-show="hasData"
          >
            <ul class="colUl" v-show="hasData">
              <li v-for="(item,index) in List" :key="index" @click="toDetail(item)">
                <div class="col_img" :style="{ 'background': 'url(' + item.image + ')'}"></div>
                <div class="col_txt">
                  <div>
                    <h2>{{item.title}}</h2>
                    <p v-html="item.summary"></p>
                  </div>
                  <div class="readfoot">
                    <div class="dt">{{item.time}}</div>
                    <div class="read">阅读 {{item.read}}</div>
                  </div>
                </div>
              </li>
            </ul>
          </van-list>
          <van-empty description="暂无内容" v-show="!hasData" />
        </div>
      </div>
      <!-- 弹窗 -->
      <van-popup v-model="filterShow" position="top" class="filterPop" @close="closeFilter">
        <div class="radioBox">
          <h2>工作年限</h2>
          <van-radio-group v-model="year">
            <div
              class="radioItem"
              :class="{active:item.radio == yearActive }"
              v-for="item,index in filterYear"
              @click="getFilterYear(item)"
            >
              <van-radio :name="item.radio">{{item.title}}</van-radio>
            </div>
          </van-radio-group>
        </div>
        <div class="radioBox">
          <h2>是否应届</h2>
          <van-radio-group v-model="welfare">
            <div
              class="radioItem"
              :class="{active:item.radio == welfareActive }"
              v-for="item,index in filterWelfare"
              @click="getFilterWelfare(item)"
            >
              <van-radio :name="item.radio">{{item.title}}</van-radio>
            </div>
          </van-radio-group>
          <!--  -->
        </div>
      </van-popup>
    </div>
  </router-layout>
</template>
<script>
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      infoType:"",
      loading: false,
      finished: false,
      hasData: true, //判断有无数据
      menuType: "3",
      filterShow: false,
      page: 0,
      year: "0", //工作年限
      welfare: "", //是否应届
      yearActive: "0",
      year_id: "0",
      welfareActive: "0",
      welfare_id: "0",
      filterYear: [
        { title: "不限", radio: "0" },
        { title: "1-2年", radio: "1" },
        { title: "3-5年", radio: "2" },
        { title: "6-7年", radio: "3" },
        { title: "8-10年", radio: "4" },
        { title: "10年以上", radio: "5" }
      ],
      filterWelfare: [
        { title: "不限", radio: "0" },
        { title: "应届", radio: "1" },
        { title: "非应届", radio: "2" }
      ],
      typeTitle: "分类",
      type_id: "0",
      typeVal: "",
      type: [{ text: "不限", value: "0" }],
      areaTitle: "区域",
      area_id: "0",
      areaVal: "",
      area: [{ text: "不限", value: "0" }],
      sexTitle: "性别",
      sex_id: "",
      sexVal: "",
      sex: [
        { text: "男", value: "1" },
        { text: "女", value: "2" }
      ],
      studyTitle: "学历",
      study_id: "",
      studyVal: "",
      study: [
        { text: "不限", value: "0" },
        { text: "初中及以下", value: "1" },
        { text: "高中/中专/技校", value: "2" },
        { text: "大专", value: "3" },
        { text: "本科", value: "4" },
        { text: "硕士", value: "5" },
        { text: "博士及以上", value: "6" }
      ],
      List: []
    };
  },
  mixins: [authMixin],
  created() {
    this.$store.commit("setDataLoading", true);
    this.getPlate();
    this.getArea();
    this.getAllData();
    this.infoType = this.$route.query.infoType
  },
  methods: {
    getPlate() {
      //获取分类
      this.$request({
        method: "get",
        url: "cms/plate",
        params: {
          type: this.menuType
        }
      }).then(res => {
        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let objdata = `{
                "value": "${item.id}",
                "text": "${item.title}"
               }`;
            this.type.push(JSON.parse(objdata));
          });
        }
      });
    },
    Back() {
      this.$router.back();
    },
    typeChange(val) {
      this.typeTitle = "";
      this.type_id = val;
      this.page = 0;
      this.List = [];
      this.finished = false;
      this.$store.commit("setDataLoading", true);
      this.getAllData();
    },
    areaChange(val) {
      //区域选择
      this.areaTitle = "";
      this.area_id = val;
      this.page = 0;
      this.List = [];
      this.finished = false;
      this.$store.commit("setDataLoading", true);
      this.getAllData();
    },
    sexChange(val) {
      this.sexTitle = "";
      this.sex_id = val;
      this.page = 0;
      this.List = [];
      this.finished = false;
      this.$store.commit("setDataLoading", true);
      this.getAllData();
    },
    studyChange(val) {
      this.studyTitle = "";
      this.study_id = val;
      this.page = 0;
      this.List = [];
      this.finished = false;
      this.$store.commit("setDataLoading", true);
      this.getAllData();
    },
    filterMore() {
      this.filterShow = !this.filterShow;
    },
    closeFilter() {
      this.filterShow = false;
    },
    getFilterYear(item) {
      this.yearActive = item.radio;
      this.year_id = item.radio;
    },
    getFilterWelfare(item) {
      this.welfareActive = item.radio;
      this.welfare_id = item.radio;
    },
    closeFilter() {
      this.filterShow = false;
      this.page = 0;
      this.List = [];
      this.finished = false;
      this.$store.commit("setDataLoading", true);
      this.getAllData();
    },
    toDetail(item) {
      this.$router.push({
        path: "/FindJobDetail",
        query:{
          id: item.id,
          infoType: this.infoType
        }
      });
    },
    getArea() {
      this.$request({
        method: "get",
        url: "/cms/area/"
      }).then(res => {
        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let objdata = `{
                "value": "${item.id}",
                "text": "${item.title}"
               }`;
            this.area.push(JSON.parse(objdata));
          });
        }
      });
    },
    getAllData() {
      this.$request({
        method: "get",
        url: "/cms/job/",
        params: {
          page: this.page,
          tid: this.type_id,
          area_id: this.area_id,
          sex: this.sex_id,
          education: this.study_id,
          graduates: this.welfare_id,
          work_years: this.year_id
        }
      }).then(res => {
        this.$store.commit("setDataLoading", false);
        console.log(res);
        if (res.data && res.data.length) {
          this.hasData = true;
          res.data.forEach(item => {
            if (item.imgs) {
              //有图片
              if (item.imgs.includes(",")) {
                //两张图片
                let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${item.imgs.split(",")[0]}",
                "summary": "${item.description}",
                "time": "${item.create_time.split(" ")[0]}",
                "read": "${item.num}"
               }`;
               this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
              }else{
                let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${item.imgs}",
                "summary": "${item.description}",
                "time": "${item.create_time.split(" ")[0]}",
                "read": "${item.num}"
               }`;
               this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
              }
            } else {
              //没有图片
              let objdata = `{
                "id": "${item.id}",
                "title": "${item.title}",
                "image": "${require("@/assets/images/noimg.jpg")}",
                "summary": "${item.description}",
                "time": "${item.create_time.split(" ")[0]}",
                "read": "${item.num}"
               }`;
              this.List.push(JSON.parse(objdata.replace(/[\r\n+]/g, '')))
            }
            this.loading = false;
            this.page = this.List[this.List.length - 1].id;
          });
        } else if (!res.data || !res.data.length && this.page == 0){
          //暂无内容
          this.hasData = false;
        } else if (!res.data || res.data.length == 0) {
          this.finished = true;
        }
      });
    },
    onLoad() {
      //上啦加载更多
      this.getAllData();
    },
    toSearch(){
      this.$router.push({
        path:'/JobSearch',
        query:{
          infoType: this.infoType
        }
      })
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../../assets/iconfont/iconfont.css";
.job_head {
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 20000000000000000;
  .head {
    padding: 0 0.3rem;
    height: 1.2rem;
    i {
      height: 1.4rem;
      font-size: 0.6rem;
      line-height: 1.4rem;
      float: left;
      color:#777;
    }
    .search {
      margin-left: 0.6rem;
      padding-bottom: 0 !important;
    }
  }
  .job_drop {
    border-bottom: 1px solid #eee;
    .drop_l {
      margin-right: 1.4rem;
    }
    .drop_r {
      float: right;
      text-align: center;
      width: 1.4rem;
      height: 1.25rem;
      line-height: 1.45rem;
      font-size: 0.46rem;
    }
    .drop_r.active {
      color: #fa683b;
    }
  }
}
.job_body {
  padding-top: 2.6rem;
  .colUl {
    li {
      padding: 0.3rem 0;
      margin: 0 0.3rem;
      border-bottom: 1px solid #eee;
      .col_img {
        height: 2.1rem;
        width: 3rem;
        float: left;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
      }
      .col_txt {
        height: 2.1rem;
        margin-left: 3.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2 {
          font-weight: normal;
          font-size: 0.4rem;
          margin-bottom: 0.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        p {
          color: #999;
          font-size: .35rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .readfoot {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          height: .3rem;
          .dt {
            display: flex;
            font-size: 0.34rem;
            color: #f03535;
          }
          .read {
            display: flex;
            margin-left: 0.3rem;
            color: #999;
            font-size: .32rem;
          }
        }
      }
    }
  }
}
.filterPop {
  height: auto;
  top: 2.3rem;
  padding: 0.3rem 0.3rem 0.35rem 0.3rem;
  box-sizing: border-box;
  .radioBox {
    height: auto;
    overflow: hidden;
    h2 {
      font-weight: normal;
      font-size: 0.4rem;
      margin: 0.3rem 0;
    }
    .radioItem.active {
      border: 1px solid #fa683b;
      color: #fa683b;
      background: rgba(252, 239, 235, 0.6);
    }
    .radioItem {
      position: relative;
      float: left;
      margin-left: 2%;
      width: calc(94% / 4);
      border: 1px solid #eee;
      text-align: center;
      height: 0.8rem;
      line-height: 0.8rem;
      font-size: 0.35rem;
      box-sizing: border-box;
      margin-bottom: 0.25rem;
      .van-checkbox {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0 !important;
      }
      .van-radio {
        height: 100%;
        display: flex;
        align-items: center;
        .van-icon {
          opacity: 0 !important;
        }
      }
    }
    .radioItem:nth-child(4n + 1) {
      margin-left: 0;
    }
  }
}
</style>